import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

import HeroStacked from "../components/Hero/HeroStacked";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Financial Reporting Services | Cash Flow Management | Smart"
            description="With Smart Accounting Hub's financial reporting services, we’ll set up custom or specialized reports on a regular basis to help you get in great shape."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroStacked
            image={data.hero.childImageSharp.gatsbyImageData}
            backgroundFixed={true}
            imageMaxHeight="max-h-[468px]"
            heading="Financial Reporting Services"
            subtext="Keep your business running smoothly, growing properly, and staying financially stable."
            textMaxWidth="max-w-4xl"
         />

         <section className="py-20 md:py-32">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="Intro" />
                  </div>
                  <div>
                     <h2>Smart Financial Reporting for Your Business</h2>
                     <p>
                        Smart businesses have financial reporting under control, and that includes thinking about cash flow management. In our
                        experience, many owners don’t do a good enough job tracking how much money is flowing in and out of their business. With our
                        financial reporting services, we’ll set up custom or specialized reports on a regular basis to help you get in great shape.
                     </p>
                     <ButtonSolid modal="modal-contact" text="Get a Quote" />
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <header className="mb-12 md:mb-16">
                  <h2>Why Outsource Financial Reporting?</h2>
               </header>
               <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <h3 className="text-lg mb-2">Save Time</h3>
                     <p className="mb-0">Analyzing your cash flow and preparing financial reports can take a long time. Leave it in our hands.</p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Save Money</h3>
                     <p className="mb-0">We’re a great solution for small business owners who are strapped for cash. Avoid expensive pitfalls.</p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Stay Accurate</h3>
                     <p className="mb-0">You can’t afford to make mistakes with your finances. We give you error-free analyses of your data.</p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Get Smarter</h3>
                     <p className="mb-0">Our reports help you enjoy improved, data-driven decision-making to positively impact your business.</p>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs />

         <Testimonials className="pt-20 md:pt-32 mb-20 md:mb-32" />

         <section className="pb-20 md:pb-32">
            <div className="container">
               <header className="mb-14 md:mb-18">
                  <h2>Related Services</h2>
               </header>
               <div className="grid md:grid-cols-3 md:gap-x-10 gap-y-12">
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.personalBookkeeping.childImageSharp.gatsbyImageData}
                           alt="Personal Bookkeeping"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Personal Bookkeeping</h3>
                     <p className="mb-0">With our personal bookkeeping, stay on track personally so you can thrive professionally.</p>
                     <AniLink fade to="/personal-bookkeeping-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.businessBookkeeping.childImageSharp.gatsbyImageData}
                           alt="Business Bookkeeping Services"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Business Bookkeeping</h3>
                     <p className="mb-0">We’ll reconcile your business revenue, expenses, and bank statements each month.</p>
                     <AniLink fade to="/business-bookkeeping-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.businessManagement.childImageSharp.gatsbyImageData}
                           alt="Business Management Services"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Business Management</h3>
                     <p className="mb-0">We organize or create new processes to help your business operations be more efficient.</p>
                     <AniLink fade to="/small-business-management-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
               </div>
            </div>
         </section>

         <CallToAction subText="Get the small business management services you need. Call or request a quote today!" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Financial Reporting.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Financial Reporting.jpg" }) {
         publicURL
      }
      hero: file(relativePath: { eq: "services/Financial Reporting/1.0 Financial Reporting.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "services/Financial Reporting/2.0 Financial Reporting Intro.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      personalBookkeeping: file(relativePath: { eq: "services/Personal Bookkeeping Services.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessBookkeeping: file(relativePath: { eq: "services/Business Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessManagement: file(relativePath: { eq: "services/Business Management Services.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      financialReporting: file(relativePath: { eq: "services/Financial Reporting.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
